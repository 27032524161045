/* You can add global styles to this file, and also import other style files */
@import "variables";

@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_mixins";
@import "bootstrap/scss/_utilities";
@import "bootstrap/scss/_root";
@import "bootstrap/scss/_reboot";

@import "poppins";

body {
    border-top: 1vh $primary solid;
    color: $white;
}