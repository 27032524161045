@import "mixins";

@include font-face(Poppins, "/font/Poppins-Thin", 100, normal, ttf);
@include font-face(Poppins, "/font/Poppins-ThinItalic", 100, italic, ttf);

@include font-face(Poppins, "/font/Poppins-ExtraLight", 200, normal, ttf);
@include font-face(Poppins, "/font/Poppins-ExtraLightItalic", 200, italic, ttf);

@include font-face(Poppins, "/font/Poppins-Light", 300, normal, ttf);
@include font-face(Poppins, "/font/Poppins-LightItalic", 300, italic, ttf);

@include font-face(Poppins, "/font/Poppins-Regular", 400, normal, ttf);
@include font-face(Poppins, "/font/Poppins-Italic", 400, italic, ttf);

@include font-face(Poppins, "/font/Poppins-Medium", 500, normal, ttf);
@include font-face(Poppins, "/font/Poppins-MediumItalic", 500, italic, ttf);

@include font-face(Poppins, "/font/Poppins-SemiBold", 600, normal, ttf);
@include font-face(Poppins, "/font/Poppins-SemiBoldItalic", 600, italic, ttf);

@include font-face(Poppins, "/font/Poppins-Bold", 700, normal, ttf);
@include font-face(Poppins, "/font/Poppins-BoldItalic", 700, italic, ttf);

@include font-face(Poppins, "/font/Poppins-ExtraBold", 800, normal, ttf);
@include font-face(Poppins, "/font/Poppins-ExtraBoldItalic", 800, italic, ttf);

@include font-face(Poppins, "/font/Poppins-Black", 900, normal, ttf);
@include font-face(Poppins, "/font/Poppins-BlackItalic", 900, italic, ttf);
