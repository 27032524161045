// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
        otf: "opentype",
        ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

@mixin linear-gradient($property, $direction, $colors) {
    #{$property}: -webkit-linear-gradient($direction, $colors);
    #{$property}: -moz-linear-gradient($direction, $colors);
    #{$property}: -o-linear-gradient($direction, $colors);
    #{$property}: linear-gradient($direction, $colors);
}

@mixin opacity($value) {
    opacity: calc(#{$value}/100);
    filter: alpha(opacity=$value);
    -moz-opacity: calc(#{$value}/100);
}

@mixin border-radius($length) {
    -moz-border-radius: $length;
    -webkit-border-radius: $length;
    border-radius: $length;
}