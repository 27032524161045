$primary: #00d9ff;
$secondary: #001e7f;
$font-family-sans-serif: "Poppins";
$headings-font-family: "Poppins";

$opacity: 70;
$primary-gradient: rgba(#cb1e8f, 0.9) 0%, rgba(#5139ae, 0.5) 100%;
$secondary-gradient: #00d9ff 0%, #095ebb 100%;
$tertiary-gradient: #ffa465 0%, #ff63b0 100%;
$quaternary-gradient: #ff63b0 0%, rgba(#ffa465, 0.75) 75%, rgba(#ffa465, 0.1) 100%;
$button-arrows-color: #cb1e8f;
$aside-height: 15vh;
$circle-margin-top: 5vh;
$circle-margin-bottom: 20vh;
$images: "/assets/image/";
$articles-background-images: "#{$images}article/";
$article-1-background-image: '#{$articles-background-images}article-1.png';
$article-2-background-image: "#{$articles-background-images}article-2.png";
$article-3-background-image: "#{$articles-background-images}article-3.png";
$article-4-background-image: "#{$articles-background-images}article-4.png";
$clips-image: "#{$images}clips.png";
$form-animation-duration: 0.4s;